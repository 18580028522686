// src/components/UserTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../utils/constants';

function UsersTable({ onEditUser, onEditPermissions }) {
  const [users, setUsers] = useState([]);
  const [routes, setRoutes] = useState([]); // State to store available routes
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Handle the user edit click
  const handleEditClick = (userId) => {
    onEditUser(userId);
  };

  // Handle user deletion
  const handleDeleteClick = async (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        await axios.delete(`${API_URL}/admin/delete/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setUsers(users.filter((user) => user.id !== userId));
      } catch (error) {
        setError('Failed to delete user');
      }
    }
  };

  // Fetch users from API
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/read/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      setError('Failed to fetch users');
    }
  };

  // Fetch routes from API (to map route name to route id)
  const fetchRoutes = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/read/routes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setRoutes(response.data);
    } catch (error) {
      console.error('Failed to fetch routes');
    }
  };

  // Fetch both users and routes on component mount
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchUsers(), fetchRoutes()]);
      setLoading(false);
    };
    fetchData();
  }, []);

  // Handle updating funds for a given user and route
  const handleUpdateFunds = async (userId, routeName) => {
    // Find the route id from the fetched routes using the route name
    const route = routes.find((r) => r.name === routeName);
    if (!route) {
      alert(`Route with name "${routeName}" not found`);
      return;
    }
    // Prompt the admin to enter a new funds value
    const newFundsStr = window.prompt(`Enter new funds value for ${routeName}:`);
    if (newFundsStr === null) return; // User cancelled prompt
    const newFunds = parseInt(newFundsStr, 10);
    if (isNaN(newFunds)) {
      alert("Invalid funds value");
      return;
    }
    try {
      const response = await axios.put(
        `${API_URL}/admin/update/user/${userId}/route/${route.id}/funds`,
        { funds: newFunds },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      alert(response.data.message);
      // Re-fetch users to update the table data
      fetchUsers();
    } catch (err) {
      console.error(err);
      alert("Failed to update funds");
    }
  };

  return (
    <div className="container mx-auto p-4 rounded-xl border border-gray-800 bg-black">
      <h1 className="text-2xl font-bold text-center text-white mb-6 border border-gray-700 rounded-full">
        Users
      </h1>
      {loading ? (
        <p>Loading users...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full leading-normal table-auto bg-black border-collapse">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Username
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Active
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Permissions
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Funds
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-white uppercase tracking-wider">
                  Actions
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-500 bg-black text-left text-xs font-semibold text-red-600 uppercase tracking-wider">
                  Danger Zone
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {user.username}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {user.is_active ? (
                      <span className="inline-block bg-green-500 rounded-full px-3 py-1 text-sm font-semibold text-black mr-2">
                        Yes
                      </span>
                    ) : (
                      <span className="inline-block bg-red-500 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2">
                        No
                      </span>
                    )}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {user.permissions.map((permission) => (
                      <span
                        key={permission.id}
                        className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
                      >
                        {permission.name}
                      </span>
                    ))}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    {user.routes_funds && user.routes_funds.length > 0 ? (
                      user.routes_funds.map((fund) => (
                        <div key={fund.route_name} className="flex items-center">
                          <span>
                            {fund.route_name}: {fund.funds}
                          </span>
                          <button
                            onClick={() => handleUpdateFunds(user.id, fund.route_name)}
                            className="ml-2 px-2 py-1 text-xs border rounded text-white hover:bg-gray-100 hover:text-black"
                          >
                            Edit Funds
                          </button>
                        </div>
                      ))
                    ) : (
                      <span className="text-gray-500">No Funds</span>
                    )}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    <button
                      onClick={() => handleEditClick(user.id)}
                      className="inline-flex px-3 py-2 text-base font-light text-center text-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-black"
                    >
                      Edit
                    </button>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-800 bg-black text-sm">
                    <button
                      onClick={() => handleDeleteClick(user.id)}
                      className="mx-2 inline-flex px-3 py-2 text-base font-light text-center text-red-600 border border-red-600 rounded-lg hover:bg-gray-100 hover:text-black"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default UsersTable;
